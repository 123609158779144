import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React, { useContext, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import FilterNav from '../../components/FilterNav'
import NoResultsText from '../../components/NoResultsText'
import { PageContext } from '../../context/ApplicationState'

const HonorRollSection = styled.section`
  .multi-column-section {
    column-count: 1;
    column-gap: 0;
    line-height: 1.5;
    text-align: center;
    font-size: 16px;
  }

  padding-top: var(--section-padding);

  > .label {
    font-size: 18px;
    line-height: 18px;
    text-align: left;
    margin-bottom: 18px;
  }
  > .rank {
    margin-top: 20px;
    font-weight: 500;
    margin-bottom: 24px;
  }

  > .names {
    font-size: 14px;
    margin: auto;
    max-width: 800px;
    line-height: 18px;
    text-transform: uppercase;
    color: ${props => props.theme.colors.darkText};
  }

  .gatsby-image-wrapper {
    width: var(--image-size) !important;
    height: var(--image-size) !important;
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 500px) {
  .multi-column-section > ul, .multi-column-section > ul > li {
    list-style: circle;
  }

  .multi-column-section {
    column-count: 2;
    column-gap: 30px;
    text-align: left;
  }
}

@media screen and (min-width: ${props => props.theme.responsive.medium}) {
  .multi-column-section {
    column-count: 3;
  }
}

@media screen and (min-width: ${props => props.theme.responsive.large}) {
--image-size: 700px;
--object-size: 156px;
}
`
const ArtistAvatarsList = styled.ul`
  --image-size: 92px;
  --object-size: 109px;
  --grid-x-gap: 8px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--object-size), 1fr));
  grid-gap: 20px var(--grid-x-gap);
  max-width: calc((var(--object-size) * 4) + (var(--grid-x-gap) * 4));
  justify-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  li {
    width: 100%;
    min-width: var(--object-size);
    text-align: center;
    .gatsby-image-wrapper {
      width: var(--image-size) !important;
      height: var(--image-size) !important;
      margin-bottom: 8px;
      &,
      img {
        border-radius: 50%;
      }
    }
    .name {
      margin-bottom: 6px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      color: ${props => props.theme.colors.darkText};
      @media screen and (min-width: ${props => props.theme.responsive.large}) {
        font-size: 15px;
      }
    }
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    --image-size: 156px;
    --object-size: 156px;
    --grid-x-gap: 30px;
  }
`

const HonorRoll = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulHonorRoll(
        filter: { title: { eq: "Honor Roll Recognition Page" } }
      ) {
        edges {
          node {
            date: date(formatString: "MMMM YYYY")
            topSellerProfiles {
              id
              name
              location
              image {
                fixed(width: 156, quality: 70) {
                  ...GatsbyContentfulFixed_withWebp
                }
              }
            }
            topSellerProfilesCanada {
              id
              name
              location
              image {
                fixed(width: 156, quality: 70) {
                  ...GatsbyContentfulFixed_withWebp
                }
              }
            }
            topEnrollerProfiles {
              id
              name
              location
              image {
                fixed(width: 156, quality: 70) {
                  ...GatsbyContentfulFixed_withWebp
                }
              }
            }
            topEnrollerProfilesCanada {
              id
              name
              location
              image {
                fixed(width: 156, quality: 70) {
                  ...GatsbyContentfulFixed_withWebp
                }
              }
            }
            rankAdvancement {
               title
               description
               heroImage {
                 fluid(
                   sizes: "(max-width: 1000px)"
                   quality: 70
                 ) {
                     ...GatsbyContentfulFluid_withWebp
                 }
               }
               date
               us {
                 rank
                 children {
                   internal {
                     content
                   }
                 }
               }
               canada {
                 rank
                 children {
                   internal {
                     content
                   }
                 }
               }
               showAsSection
            }
            topSales {
              title
              description
              heroImage {
                fluid(
                  sizes: "(max-width: 1000px)"
                  quality: 70
                ) {
                    ...GatsbyContentfulFluid_withWebp
                }
              }
              date
              us {
                rank
                children {
                  internal {
                    content
                  }
                }
              }
              canada {
                rank
                children {
                  internal {
                    content
                  }
                }
              }
              showAsSection
            }
            topEnrollers {
              title
              description
              heroImage {
                fluid(
                  sizes: "(max-width: 1000px)"
                  quality: 70
                ) {
                    ...GatsbyContentfulFluid_withWebp
                }
              }
              date
              us {
                rank
                children {
                  internal {
                    content
                  }
                }
              }
              canada {
                rank
                children {
                  internal {
                    content
                  }
                }
              }
              showAsSection
            }
            activeDirects {
              title
              description
              heroImage {
                fluid(
                  sizes: "(max-width: 1000px)"
                  quality: 70
                ) {
                    ...GatsbyContentfulFluid_withWebp
                }
              }
              date
              us {
                rank
                children {
                  internal {
                    content
                  }
                }
              }
              canada {
                rank
                children {
                  internal {
                    content
                  }
                }
              }
              showAsSection
            }
            maintainedRank {
              title
              description
              heroImage {
                fluid(
                  sizes: "(max-width: 1000px)"
                  quality: 70
                ) {
                    ...GatsbyContentfulFluid_withWebp
                }
              }
              date
              us {
                rank
                children {
                  internal {
                    content
                  }
                }
              }
              canada {
                rank
                children {
                  internal {
                    content
                  }
                }
              }
              showAsSection
            }
            leadingArtist {
              title
              description
              heroImage {
                fluid(
                  sizes: "(max-width: 1000px)"
                  quality: 70
                ) {
                    ...GatsbyContentfulFluid_withWebp
                }
              }
              date
              us {
                rank
                children {
                  internal {
                    content
                  }
                }
              }
              canada {
                rank
                children {
                  internal {
                    content
                  }
                }
              }
              showAsSection
            }
            ambitiousArtist {
               title
               description
               heroImage {
                 fluid(
                   sizes: "(max-width: 1000px)"
                   quality: 70
                 ) {
                     ...GatsbyContentfulFluid_withWebp
                 }
               }
               date
               us {
                 rank
                 children {
                   internal {
                     content
                   }
                 }
               }
               canada {
                 rank
                 children {
                   internal {
                     content
                   }
                 }
               }
               showAsSection
            }
            goGetters {
              title
              description
              heroImage {
                fluid(
                  sizes: "(max-width: 1000px)"
                  quality: 70
                ) {
                    ...GatsbyContentfulFluid_withWebp
                }
              }
              date
              us {
                rank
                children {
                  internal {
                    content
                  }
                }
              }
              canada {
                rank
                children {
                  internal {
                    content
                  }
                }
              }
              showAsSection
            }
            artistsByLocationUs {
              title
              fluid(
                sizes: "(max-width: 1400px)"
                quality: 70
                ) {
                  ...GatsbyContentfulFluid_withWebp
                }
            }
            artistsByLocationCa {
              title
              fluid(
                sizes: "(max-width: 1400px)"
                quality: 70
                ) {
                  ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const honorRollData = data.allContentfulHonorRoll.edges[0].node
  const allSections = Object.values(honorRollData).filter(section => section.showAsSection === true)

  const { pageState, setPageState } = useContext(PageContext)

  const multiColumnSections = ['Maintained Rank', 'Leading Artist', 'Go-Getters', 'Ambitious Artist']

  useEffect(() => {
    setPageState(pageState => ({
      ...pageState,
      hasStickySiteNav: true,
      hasHeroSection: false,
    }))
  }, [])

  useEffect(() => {
    document.querySelector('body').classList.add('has-light-header')
    return () =>
      document.querySelector('body').classList.remove('has-light-header')
  }, [])

  const [country, setCountry] = useState(1)

  return (
    <div className="honor-roll-container wrap text-center">
      {allSections || honorRollData ? (
        <>
          <h2 className="headline--small">{honorRollData.date} Honor Roll</h2>
          <p>
            Congratulations to the top performing Seint Artists for the past
            month{' '}
          </p>

          <HonorRollSection>
            <FilterNav className="country">
              <button
                className={
                  country === 1 ? 'is-active' : undefined
                }
                onClick={() => setCountry(1)}
              >
                United States
              </button>
              <button
                className={
                  country === 2 ? 'is-active' : undefined
                }
                onClick={() => setCountry(2)}
              >
                Canada
              </button>
            </FilterNav>
          </HonorRollSection>

          <HonorRollSection>
            {(honorRollData.topSellerProfiles ||
              honorRollData.topSellerProfilesCanada ||
              honorRollData.topEnrollerProfiles ||
              honorRollData.topEnrollerProfilesCanada) && (
                <p style={{ textAlign: 'center' }} className="label">Top Sellers and Top Enrollers</p>
              )}
            {(honorRollData.topSellerProfiles ||
              honorRollData.topSellerProfilesCanada) && (
                <p className="rank">Top Sellers</p>
              )}
            <ArtistAvatarsList>
              {(country === 1 ||
                !honorRollData.topSellerProfilesCanada) &&
                honorRollData.topSellerProfiles.map(topSeller => (
                  <li key={topSeller.id}>
                    <figure>
                      <Img fixed={topSeller.image.fixed} alt={topSeller.name} />
                      <figcaption>
                        <p className="name">{topSeller.name}</p>
                        <p className="detail">{topSeller.location}</p>
                      </figcaption>
                    </figure>
                  </li>
                ))}
              {(country === 2 ||
                !honorRollData.topSellerProfiles) &&
                honorRollData.topSellerProfilesCanada.map(topSeller => (
                  <li key={topSeller.id}>
                    <figure>
                      <Img fixed={topSeller.image.fixed} alt={topSeller.name} />
                      <figcaption>
                        <p className="name">{topSeller.name}</p>
                        <p className="detail">{topSeller.location}</p>
                      </figcaption>
                    </figure>
                  </li>
                ))}
            </ArtistAvatarsList>

            {(honorRollData.topEnrollerProfiles ||
              honorRollData.topEnrollerProfilesCanada) && (
                <p className="rank">Top Enrollers</p>
              )}
            <ArtistAvatarsList>
              {(country === 1 ||
                !honorRollData.topEnrollerProfilesCanada) &&
                honorRollData.topEnrollerProfiles.map(topEnroller => (
                  <li key={topEnroller.id}>
                    <figure>
                      <Img fixed={topEnroller.image.fixed} alt={topEnroller.name} />
                      <figcaption>
                        <p className="name">{topEnroller.name}</p>
                        <p className="detail">{topEnroller.location}</p>
                      </figcaption>
                    </figure>
                  </li>
                ))}
              {(country === 2 ||
                !honorRollData.topEnrollerProfiles) &&
                honorRollData.topEnrollerProfilesCanada.map(topEnroller => (
                  <li key={topEnroller.id}>
                    <figure>
                      <Img fixed={topEnroller.image.fixed} alt={topEnroller.name} />
                      <figcaption>
                        <p className="name">{topEnroller.name}</p>
                        <p className="detail">{topEnroller.location}</p>
                      </figcaption>
                    </figure>
                  </li>
                ))}
            </ArtistAvatarsList>
          </HonorRollSection>

          {
            allSections.map(section => (
              ((section.us && section.us.filter(level => level.rank !== null).length !== 0) || (section.canada && section.canada.filter(level => level.rank !== null).length !== 0)
              ) && <HonorRollSection className={section.title}>

                <div>
                  <h2 style={{ display: 'inline-block', fontSize: '24px', textTransform: 'uppercase', verticalAlign: 'top', marginRight: '20px' }}>{section.title}</h2>
                  <p style={{ display: 'inline-block', maxWidth: '400px', textTransform: 'uppercase', lineHeight: '18px' }}>{section.description}</p>
                  {section.heroImage ? (
                    <Img style={{ margin: 'auto', maxWidth: '1000px', maxHeight: '300px' }} fluid={section.heroImage.fluid} alt="foo" />
                  ) : ('')
                  }
                </div>
                {country === 1 ?
                  section.us && section.us.filter(rankLevel => rankLevel.children.length > 0).length > 0 ?
                    section.us.map(rankLevel => (
                      <>
                        {rankLevel.children[0] && <p style={{ textTransform: 'uppercase' }} className="rank">{rankLevel.rank}</p>}
                        <div style={{ margin: 'auto', textTransform: 'uppercase', maxWidth: '700px' }}>
                          <div className={multiColumnSections.includes(section.title) ? 'multi-column-section' : ''}>
                            <ReactMarkdown>{rankLevel.children[0] && rankLevel.children[0].internal.content}</ReactMarkdown>
                          </div>
                        </div>
                      </>
                    ))
                    : (
                      <p className="rank">
                        We haven't added any {section.title} for US yet 😢
                      </p>
                    )
                  :
                  section.canada && section.canada.filter(rankLevel => rankLevel.children.length > 0).length > 0 ?
                    section.canada.map(rankLevel => (
                      <>
                        {rankLevel.children[0] && <p style={{ textTransform: 'uppercase' }} className="rank">{rankLevel.rank}</p>}
                        <div style={{ margin: 'auto', textTransform: 'uppercase', maxWidth: '700px' }}>
                          <div className={multiColumnSections.includes(section.title) ? 'multi-column-section' : ''}>
                            <ReactMarkdown>{rankLevel.children[0] && rankLevel.children[0].internal.content}</ReactMarkdown>
                          </div>
                        </div>
                      </>
                    ))
                    : (
                      <p className="rank">
                        We haven't added any {section.title} for Canada yet 😢
                      </p>
                    )
                }
              </HonorRollSection>
            ))
          }
          <HonorRollSection>
            {country === 1 ? (
              <>
                <h2 style={{ textAlign: 'center', fontSize: '24px', lineHeight: '24px', marginBottom: '16px' }} className="label">Artists by Location</h2>
                <img style={{ maxWidth: '700px', margin: 'auto' }} src={honorRollData.artistsByLocationUs.fluid.src} alt={honorRollData.artistsByLocationUs.title} />
              </>
            ) : (
              <>
                <h2 style={{ textAlign: 'center', fontSize: '24px', lineHeight: '24px', marginBottom: '16px' }} className="label">Artists by Location</h2>
                <img style={{ maxWidth: '700px', margin: 'auto' }} src={honorRollData.artistsByLocationCa.fluid.src} alt={honorRollData.artistsByLocationCa.title} />
              </>
            )}
          </HonorRollSection>
        </>
      ) : <NoResultsText>
        We haven't added any "Honor Roll" yet 😢
      </NoResultsText>
      }
    </div>
  )
}

export default HonorRoll
